import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams
} from '@ngx-translate/core';

export class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    if (params.interpolateParams) {
      return params.interpolateParams['default'] ?? params.key;
    }

    return params.key;
  }
}
